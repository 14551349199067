<template>
  <div class="periods flex flex-column gap-4">

    <div class="font-bold">
      Showing {{ periods.length | format }} period(s)
    </div>
    <table class="table table-google table-periods">
      <thead>
        <tr>
          <th>Order</th>
          <th>Description</th>
          <th>Abbreviation</th>
          <th>Issues</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td colspan="8">
            <div class="font-bold">
              <span v-if="isLoading">Loading..</span>
              <span v-else-if="periods.length === 0">No periods found</span>
            </div>
          </td>
        </tr>

        <tr v-for="period in periods" :key="period.id">
          <td class="row-fit text-right">{{ period.period_order }}</td>
          <td>{{ period.period_description }}</td>
          <td class="row-fit">{{ period.period_short }}</td>
          <td class="row-fit text-right">{{ period.period_issues.length }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'Periods',
  components: {},
  computed: {
    isLoading() {
      return this.loadingCount > 0;
    },
  },
  data() {
    return {
      loadingCount: 0,
      periods: [],
    };
  },
  methods: {
    fetchPeriods() {
      this.loadingCount++;
      this.$http
        .get('/burn_sample/period')
        .then((res) => {
          this.periods = res.body.periods;
        })
        .catch((err) => {
          this.$toast.error(`Failed to fetch periods: ${err.response.text}`);
        })
        .finally(() => {
          this.loadingCount--;
        });
    },
  },
  created() {
    this.fetchPeriods();
  },
};
</script>

<style lang="scss" scoped>
  .periods {
    max-width: 500px;
  }
</style>
